<template>
  <div class="bc-shop-product">
    <div class="component component__padding page">

      <div class="bc-shop-product__pict-back">
        <div class="bc-shop-product__pict-back__back" v-if="hasHistory()" @click="$router.go(-1) ">
          <img src="@/assets/img/icons/back.svg" alt="">
        </div>
        <div class="bc-shop-product__pict-back__title" v-html="product.title"></div>
        <img :src="product.picture_main" alt="product-picture" class="bc-shop-product__pict-back__img">
      </div>

      <div class="bc-shop-product__content">
        <div class="bc-shop-product__content__title" v-html="product.title"></div>
        <div class="bc-shop-product__content__subtitle" v-html="product.subtitle"></div>
        <div class="bc-shop-product__content__options" v-if="product.options">
          <div class="bc-shop-product__content__options__item"
               v-for="option in product.options" :key="option.id">
            <bc-button
                class="bc-shop-product__content__options__item__btn"
                :class="{error: errorPreferredProductOption}"
                :color-type="preferredProductOption === option ? 'black' : 'white'"
                @click="setPreferredProductOption(option)">
              {{ option.title }} {{ product.measure }}
            </bc-button>
          </div>
          <transition name="slide-fadeUp">
            <span class="bc-shop-product__content__options__error"
                  v-if="errorPreferredProductOption">
              {{ $t('Обовʼязкове полеееееее') }}
            </span>
          </transition>
        </div>
        <div class="bc-shop-product__content__count">
          <bc-count-control
              :count-model="preferredProductCount"
              @increase-count="preferredProductCount += 1"
              @decrease-count="preferredProductCount > 1 ? preferredProductCount -= 1 : {}"/>
        </div>
        <div class="bc-shop-product__content__price">{{ productPrice }}&nbsp;{{ $t('INTERFACE.CURRENCY') }}</div>
        <div class="bc-shop-product__content__description">
          <div v-html="showFullDescription ? product.description : product.description_short"></div>
          <div v-if="product.description" @click="showFullDescription = !showFullDescription"
               class="bc-shop-product__content__description__switch">
            {{ showFullDescription ? $t("SHOP.HIDE") : $t("SHOP.MORE") }}
          </div>
        </div>
        <bc-button class="bc-modal-product__content__btn" :color-type="isInCart ? 'white' : 'black'"
                   :is-big="true" @click="addToCart">
          <span v-if="isInCart">{{ $t('SHOP.IN_CART') }}</span>
          <span v-else>{{ $t('SHOP.BUY') }}</span>
        </bc-button>
      </div>
    </div>
    <bc-ordered-product-popup/>
  </div>
</template>

<script>
import {computed} from "vue";
// import {usePageTitle} from "@/utils";
import bcOrderedProductPopup from "@/components/UI/dialogs/bc-ordered-product-popup";
import {useHead} from "@vueuse/head";
// import {store} from "@/store";

export default {
  name: "bc-shop-product",
  components: {bcOrderedProductPopup},
  created() {
    this.fetchData()
    useHead({
      // Can be static or computed
      title: computed(() => this.seoTitle || `${this.product.title}`),
      meta: [
        {
          name: `description`,
          content: computed(() => this.seoDescription || `${this.product.subtitle}`),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => this.seoTitle || `${this.product.title}`),
    //     computed(() => this.seoDescription || `${this.product.subtitle}`))
  },
  computed: {
    isInCart() {
      return !!this.$store.getters.cartItems.find(
          i => i.id === this.product.id
              && i.preferred_option?.id === this.preferredProductOption?.id
      )
    }
  },
  beforeUnmount() {
    this.$store.commit('setIsActiveOrderPopup', false)
  },
  methods: {
    setProductPrice() {
      if (this.product.options.length) {
        this.preferredProductOption = this.product.options[0]
        this.productPrice = this.preferredProductOption.price
      } else {
        this.productPrice = this.product.price
      }
    },
    fetchData() {
      this.$http.get(`/api/shop/${this.$route.params.productSlug}/`).then(resp => {
        this.product = resp.data
        this.seoTitle = resp.data.seo_title
        this.seoDescription = resp.data.seo_description

        this.setProductPrice()
      }).catch(err => {
        console.log('error!')
        this.error = true
        console.error(err)
      })
    },
    addToCart() {
      if (this.product.options && !this.preferredProductOption) {
        return this.errorPreferredProductOption = true
      }

      if (this.isInCart) {
        return
      }

      this.errorPreferredProductOption = false

      this.$store.commit('addToCart', {
        product: {
          id: this.product.id,
          picture_small: this.product.picture_small,
          title: this.product.title,
          preferred_option: this.preferredProductOption,
          measure: this.product.measure,
          price: this.productPrice,
          slug: this.product.slug
        },
        amount: this.preferredProductCount
      })
      this.preferredProductCount = 1
      this.$store.commit('setIsActiveOrderPopup', true)
    },

    setPreferredProductOption(option) {
      this.preferredProductOption = option
      this.productPrice = option.price
      this.preferredProductCount = 1
      this.errorPreferredProductOption = false
    },

    hasHistory() {
      return window.history.length > 2
    }
  },

  data() {
    return {
      product: {},
      showFullDescription: false,
      preferredProductOption: {},
      errorPreferredProductOption: false,
      preferredProductCount: 1,
      productPrice: null,
      seoTitle: 'Beauty Code',
      seoDescription: 'Beauty Code'
    }
  }
}
</script>

<style scoped lang="scss">
.bc-shop-product {

  .component {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__pict-back {
    position: relative;

    &__title {
      display: none;
    }

    &__back {
      position: absolute;
      padding: .8em;
      border-radius: 50%;
      background-color: #F4F4F4;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 100;

      img {
        padding-right: 5px;
        height: 25px;
        width: 25px;
      }
    }

    &__img {
      position: sticky;
      top: var(--header-height);
      height: 730px;
      width: 640px;
      padding-right: 20px;
    }
  }


  &__content {
    margin-top: 40px;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    > div:not(:first-child),
    > button {
      margin: 15px 0;
    }

    &__title {
      font-size: 1.5em;
    }

    &__subtitle {
      color: $dark-gray;
    }

    &__options {
      position: relative;
      display: flex;

      &__item {
        font-size: .9em;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        &__btn {
          padding: .6em .9em;
        }


      }

      &__error {
        position: absolute;
        text-align: end;
        color: $error;
        font-size: .675em;
        bottom: -22px;
        width: 100%;
      }
    }

    &__price {
      font-size: 1.45em;
      margin-bottom: 30px;
    }

    &__description {
      color: $dark-gray;
      font-size: .75em;
      line-height: 1.6em;

      &__switch {
        color: $black;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .bc-shop-product {
    &__pict-back {
      &__img {
        object-position: top;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .bc-shop-product {
    .component {
      flex-direction: column;
    }

    &__pict-back {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__back {
        display: none;
      }

      &__title {
        display: block;
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 15px;
      }

      &__img {
        position: static;
        top: unset;
        max-height: 35vh;
        width: unset;
        padding-right: unset;
      }
    }

    &__content {
      width: 100%;
      align-items: center;
      margin-top: 20px;

      &__title {
        display: none;
      }

      &__subtitle {
        text-align: center;
      }

      > div:not(:first-child),
      > button {
        margin: 9px 0;
      }

      &__description {
        text-align: center;

        &__switch {
          margin-top: 10px;
          text-align: center;
          font-size: 1.3em;
        }
      }
    }
  }
}
</style>
